import React, { useEffect, useState } from 'react';
import GridCenter from 'pages/App/components/GridCenter';
import { Dimmer, Grid, Loader, Label, } from 'semantic-ui-react';
import Header from '../Header'
import { CardChart, CardChartDescription, AvgTitle, AvgSubtitle, } from '../Helpers'
import { useOrganisationHook } from 'services/hooks/useOrganisationHook';
import { useProjectHook } from 'services/hooks/useProjectHook';
import moment from 'moment';
import pluralize from 'pluralize';
import { ResponsivePie } from '@nivo/pie'
import { useInsightsHook } from 'services/hooks/useInsightsHook';
import { BadgeSubject, BadgeUser } from 'pages/App/components/Badges';
import { useFirestoreDocDataOnce } from 'reactfire';
import { useFirestoreHook } from 'services/hooks/useFirestoreHook';
import { ResponsiveBar } from '@nivo/bar';

function InsightsGlobal({ oid }: any) {

  const { collections: { HWFS } } = useFirestoreHook();
  const { data: { usersDocs, subjectsDocs, teamsDocs } } = useOrganisationHook(oid)
  const { data: { loadingHook, mainData, questions } } = useInsightsHook(oid, null);
  const { data: { projectsDocs }, functions: { projectById, validateAccessHelper } } = useProjectHook(oid)

  const [loading, setLoading]: any = useState(true)
  const [totalQuestions, setTotalQuestions]: any = useState(0)
  const [mostNewProject, setMostNewProject]: any = useState({})
  const [mostActiveQuestion, setMostActiveQuestion]: any = useState({})
  const [teamsChart, setTeamsChart]: any = useState({})
  const [usersByRole, setUserByRole]: any = useState({})

  const orgAccessRef = HWFS.organisations_access.doc(oid);
  const orgAccess: any = useFirestoreDocDataOnce(orgAccessRef, { idField: 'id' });

  useEffect(() => {
    const projects = projectsDocs.filter((project: any) => validateAccessHelper(project.id)) || []

    if (loadingHook === false) {
      /* 1st Definition */
      setTotalQuestions(questions.length)

      /* 2nd Definition */
      const mostNewProject = projects.length > 0 ? projects.reduce((prev: any, current: any) => {
        return (prev.createdAt?.seconds > current.createdAt?.seconds) ? prev : current
      }) : null;
      setMostNewProject(mostNewProject)

      /* 3th Definition */
      const mostActiveQuestion = projects.length > 0 ? questions.reduce((prev: any, current: any) => {
        if (!current.commentsLength) return prev;
        return (prev.commentsLength > current.commentsLength) ? prev : current
      }): null;

      if(mostActiveQuestion){
        setMostActiveQuestion({ ...mostActiveQuestion, projectData: projectById(mostActiveQuestion.projId) })
      }else{
        setMostActiveQuestion(null)
      }

      /* 4th Definition */
      const teamsBySize = teamsDocs.map((team: any) => {
        return {
          id: team.id,
          value: team.users.length,
          label: team.name,
        }
      });
      setTeamsChart(teamsBySize)

      /* 5th Definition */
      setUserByRole([
        {
          id: 'admins',
          label: 'Admin',
          value: orgAccess.usersAdmin?.length || 0,
        },
        {
          id: 'guest',
          label: 'Guest',
          value: orgAccess.usersGuest?.length || 0,
        },
        {
          id: 'member',
          label: 'Member',
          value: orgAccess.usersMember?.length || 0,
        },
      ])
    }
    setLoading(loadingHook)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingHook, mainData]);

  const LoadingGeneric = () => (
    <>
      {
        loading &&
        <Dimmer active inverted>
          <Loader></Loader>
        </Dimmer>
      }
    </>
  )

  const ResponsivePieExtended = (props: any) =>
    <ResponsivePie
      {...props}
      arcLinkLabel={function (e) { return "" + e.label + "" }}
      margin={{ top: 25, right: 50, bottom: 80, left: 50 }}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}
      itemWidth={100}
      activeOuterRadiusOffset={10}
      borderWidth={1}
      borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: 'color' }}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
      defs={[
        {
          id: 'dots',
          type: 'patternDots',
          background: 'inherit',
          color: 'rgba(255, 255, 255, 0.3)',
          size: 4,
          padding: 1,
          stagger: true
        },
        {
          id: 'lines',
          type: 'patternLines',
          background: 'inherit',
          color: 'rgba(255, 255, 255, 0.3)',
          rotation: -45,
          lineWidth: 6,
          spacing: 10
        }
      ]}
      legends={[
        {
          anchor: 'bottom',
          direction: 'row',
          justify: false,
          translateX: 0,
          translateY: 56,
          itemsSpacing: 25,
          itemWidth: 100,
          itemHeight: 18,
          itemTextColor: '#999',
          itemDirection: 'left-to-right',
          itemOpacity: 1,
          symbolSize: 18,
          symbolShape: 'circle',
          effects: [
            {
              on: 'hover',
              style: {
                itemTextColor: '#000'
              }
            }
          ]
        }
      ]}
    />

  return (
    <>
      <Header oid={oid} project={null} />
      <GridCenter fullWidth marginTop='1em'>
        {/* FIRST ROW */}
        <Grid centered columns={3} doubling>
          <Grid.Column>
            <CardChart title='Users'>
              <CardChartDescription >
                Total number of admins, members, and guests in your organisation.
            </CardChartDescription>
              <AvgTitle>
                {usersDocs.length}
              </AvgTitle>
              <LoadingGeneric />
            </CardChart>
          </Grid.Column>
          <Grid.Column>
            <CardChart title='Discussions'>
              <CardChartDescription>
                Total number of discussions in the organisation, both public and private.
              </CardChartDescription>
              <AvgTitle>
                {projectsDocs.filter((project: any) => validateAccessHelper(project.id) && project.status === 200).length}
              </AvgTitle>
              <LoadingGeneric />
            </CardChart>
          </Grid.Column>
          <Grid.Column>
            <CardChart title='Subjects'>
              <CardChartDescription>
                Total number of subjects being discussed.
              </CardChartDescription>
              <AvgTitle>
                {subjectsDocs.filter((subject: any) => subject.status === 200).length}
              </AvgTitle>
              <LoadingGeneric />
            </CardChart>
          </Grid.Column>

          <Grid.Column>
            <CardChart title='Time to answer'>
              <CardChartDescription>
                Average time for a question to be have a selected answers (last 30 days).
              </CardChartDescription>
              <AvgTitle>
                {
                  loading ?
                    '----' :
                    mainData.timeToAnswer
                }
              </AvgTitle>
              <LoadingGeneric />
            </CardChart>
          </Grid.Column>
          <Grid.Column>
            <CardChart title='Time to comment'>
              <CardChartDescription>
                Average time for a question to have a first comment (last 30 days)
              </CardChartDescription>
              <AvgTitle>
                {
                  loading ?
                    '----' :
                    mainData.timeToComment
                }
              </AvgTitle>
              <LoadingGeneric />
            </CardChart>
          </Grid.Column>
          <Grid.Column>
            <CardChart title='Total Questions'>
              <CardChartDescription>
                Total number of questions in all discussions
              </CardChartDescription>
              <AvgTitle>
                {totalQuestions}
              </AvgTitle>
              <LoadingGeneric />
            </CardChart>
          </Grid.Column>

          <Grid.Column>
            <CardChart title='Most active discussion'>
              <CardChartDescription>
                The discussion with the most active question by comments
              </CardChartDescription>
              <AvgSubtitle
                title={mostActiveQuestion ? mostActiveQuestion.projectData?.name : 'No data'}
                subtitle={
                  mostActiveQuestion ?
                  `${mostActiveQuestion.question} (${mostActiveQuestion.commentsLength} ${pluralize('comment', mostActiveQuestion.commentsLength)})` : '-'
                }
              />
              <LoadingGeneric />
            </CardChart>
          </Grid.Column>
          {/* <Grid.Column>
            <CardChart title='Most recently active discussion'>
              <CardChartDescription>
                The discussion that has most recently received a comment, question or answer selected.
              </CardChartDescription>
              <AvgTitle>
                <Label size='big'>
                  <Icon name='plug' />
                  Coming Soon
                </Label>
              </AvgTitle>
              <LoadingGeneric />
            </CardChart>
          </Grid.Column> */}
          <Grid.Column>
            <CardChart title='Lastest discussion'>
              <CardChartDescription>
                The lastest discussion to be added
              </CardChartDescription>
              <AvgSubtitle
                title={mostNewProject ? mostNewProject.name : 'No Data'}
                subtitle={mostNewProject ? mostNewProject.createdAt && moment.utc(mostNewProject.createdAt.seconds * 1000).fromNow() : '-'}
              />
              <LoadingGeneric />
            </CardChart>
          </Grid.Column>
        </Grid>
        <Grid doubling columns={2}>
          <Grid.Column>
            <CardChart height='400px' title='User Types'>
              <CardChartDescription>
                Members, Guests and Admins
              </CardChartDescription>
              {
                !loading &&

                <ResponsivePieExtended
                  data={usersByRole}
                  tooltip={(eve: any) => {
                    return <Label basic>
                      <span style={{ marginLeft: '1em' }}>
                        {eve.datum.value} {pluralize(eve.datum.label, eve.datum.value)}
                      </span>
                    </Label>
                  }} />
              }
              <LoadingGeneric />
            </CardChart>
          </Grid.Column>
          <Grid.Column>
            <CardChart height='400px' title='Teams'>
              <CardChartDescription>
                Teams by size
            </CardChartDescription>
              {
                !loading &&
                <ResponsivePieExtended
                  data={teamsChart}
                  tooltip={(eve: any) => {
                    return <Label basic>
                      <span style={{ marginLeft: '1em' }}>
                        {eve.datum.label} : {eve.datum.value} {pluralize('member', eve.datum.value)}
                      </span>
                    </Label>
                  }} />
              }
              <LoadingGeneric />
            </CardChart>
          </Grid.Column>
          <Grid.Column>
            <CardChart height='400px' title='Subjects'>
              <CardChartDescription>
                What&#39;s being talked about
            </CardChartDescription>
              {
                !loading &&
                <ResponsivePieExtended
                  data={mainData.subjects}
                  tooltip={(eve: any) => {
                    return <Label basic>
                      <Grid columns={2}>
                        <Grid.Column>
                          <BadgeSubject oid={oid} subjectId={eve.datum.id} />
                        </Grid.Column>
                        <Grid.Column>
                          <p style={{ marginTop: '0.5em', marginLeft: '2px' }}>
                            : {eve.datum.value}
                          </p>
                        </Grid.Column>
                      </Grid>
                    </Label>
                  }} />
              }
              <LoadingGeneric />
            </CardChart>
          </Grid.Column>
          <Grid.Column>
            <CardChart height='400px' title='Question source'>
              <CardChartDescription>
                Who&#39;s asking questions
            </CardChartDescription>
              {
                !loading &&
                <ResponsivePieExtended
                  data={mainData.questionSource}
                  tooltip={(eve: any) => {
                    return <Label basic>
                      <BadgeUser oid={oid} uid={eve.datum.id} />
                      <span style={{ marginLeft: '1em' }}>
                        {eve.datum.label} : {eve.datum.value}
                      </span>
                    </Label>
                  }} />
              }
              <LoadingGeneric />
            </CardChart>
          </Grid.Column>
          <Grid.Column>
            <CardChart height='400px' title='Answered by'>
              <CardChartDescription>
                Who&#39;s comments are being selected as answers
            </CardChartDescription>
              {
                !loading &&
                <ResponsivePieExtended
                  data={mainData.answerSource}
                  tooltip={(eve: any) => {
                    return <Label basic>
                      <BadgeUser oid={oid} uid={eve.datum.id} />
                      <span style={{ marginLeft: '1em' }}>
                        {eve.datum.label} : {eve.datum.value}
                      </span>
                    </Label>
                  }} />
              }
              <LoadingGeneric />
            </CardChart>
          </Grid.Column>
          <Grid.Column>
            <CardChart height='400px' title=' Comments by'>
              <CardChartDescription>
                Who&#39;s commenting
            </CardChartDescription>
              {
                !loading &&
                <ResponsivePieExtended
                  data={mainData.commentsSource}
                  tooltip={(eve: any) => {
                    return <Label basic>
                      <BadgeUser oid={oid} uid={eve.datum.id} />
                      <span style={{ marginLeft: '1em' }}>
                        {eve.datum.label} : {eve.datum.value}
                      </span>
                    </Label>
                  }} />
              }
              <LoadingGeneric />
            </CardChart>
          </Grid.Column>
        </Grid>
        <Grid centered columns={1}>
          <Grid.Column>
            <CardChart height='400px' title='Active Days'>
              <CardChartDescription>
                The most active days for asking questions
               {/* (line shows average over last 30 days) */}
              </CardChartDescription>
              <ResponsiveBar
                data={mainData.daysOfQuestions}
                keys={['qtyQuestions']}
                indexBy="day"
                tooltip={(eve: any) => {
                  return <span>
                    {eve.data.dayExt} : {eve.data.qtyQuestions}
                  </span>
                }}
                margin={{ top: 50, right: 60, bottom: 50, left: 80 }}
                padding={0.3}
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                colors={{ scheme: 'nivo' }}
                defs={[
                  {
                    id: 'dots',
                    type: 'patternDots',
                    background: 'inherit',
                    color: '#38bcb2',
                    size: 4,
                    padding: 1,
                    stagger: true
                  },
                  {
                    id: 'lines',
                    type: 'patternLines',
                    background: 'inherit',
                    color: '#eed312',
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10
                  }
                ]}
                borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: 'day',
                  legendPosition: 'middle',
                  legendOffset: 32
                }}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: 'questions',
                  legendPosition: 'middle',
                  legendOffset: -40,
                  format: e => Math.floor(e) === e && e
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                animate={true}
                motionStiffness={90}
                motionDamping={15}
              />
            </CardChart>
          </Grid.Column>
        </Grid>
      </GridCenter >
    </>
  );
}

export default InsightsGlobal;