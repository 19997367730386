import React, { createContext, useEffect, useState } from 'react';
import * as firebase from 'firebase/app'
import 'firebase/remote-config';
import MaintenanceMode from 'pages/App/components/MaintenanceMode';

const RemoteConfProvider = ({ children }: any) => {

  const RemoteConfContext = createContext({});
  const remoteConfig = firebase.remoteConfig();

  // Firebase, Remote Config
  // Defaults, define the min status from firebase,
  // First key in this object define a specific key in remote config
  const defaults: any = {
    enviroment: {
      maintenanceMode: false
    },
    exampleHW: true,
  }

  const [remoteConf, setRemoteConf] = useState(defaults);
  const [loading, setLoading] = useState(true);

  // For development only
  remoteConfig.settings = {
    minimumFetchIntervalMillis: 1000,
  };

  const updateRemoteConfig = () => {

    setLoading(true);
    remoteConfig.defaultConfig = defaults;

    remoteConfig
      .fetchAndActivate()
      .then((activated: any) => {
        if (!activated) //console.xx('[remoteConf] not activated');
          return remoteConfig.getAll();
      })
      .then((remoteRemoteConf: any) => {
        
        const newRemoteConf = {
          ...remoteConf,
        };

        if(remoteRemoteConf){
          for (const [key, config] of Object.entries(remoteRemoteConf)) {
            let valueFlag: any = config.asString();
            if (JSON.parse(valueFlag)) valueFlag = JSON.parse(valueFlag);
            newRemoteConf[key] = valueFlag;
          }
        }

        setRemoteConf(newRemoteConf);
        setLoading(false);
      })
      .catch((err: any) =>
        console.error(err)
      );
  }

  useEffect(() => {
    updateRemoteConfig()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RemoteConfContext.Provider value={remoteConf}>
      { loading ?
        <>Loading configurations...</> :
        <>
          {
            (remoteConf.enviroment.maintenanceMode === true &&
              window.location.hostname !== 'localhost') ?
              <MaintenanceMode /> :
              <>{children}</>
          }
        </>
      }
    </RemoteConfContext.Provider>
  );
};

export { RemoteConfProvider }